import { Box, BoxProps, Dialog, Typography } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useCallback,
} from "react";
import { createPortal } from "react-dom";
import Draggable, {
  ControlPosition,
  DraggableBounds,
  DraggableProps,
} from "react-draggable";
import NewWindow from "react-new-window";
import styles from "./DraggablePopup.module.scss";
import useResizeObserver from "use-resize-observer";
import IframeResizer from "iframe-resizer-react";
import { CacheProvider } from "@emotion/react";
import { clientSideEmotionCache } from "../../../pages/_app";
import { KeyboardArrowUp } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";

interface IDraggableWindowProps {
  defaultPosition?: ControlPosition;
  open: boolean;
  onClose: (event?: Object, reason?: string) => void;
  title: string;
  children?: React.ReactNode;
  height?: number;
  width?: number;
  url?: string;
  popupToolbar?: boolean;
  bounds?: string | false | DraggableBounds;
  style?: any;
  unsetBottom?: boolean;
  hidePopup?: boolean;
  center?: boolean;
  offset?: { x: number; y: number };
}

type DialogComponentProps = Partial<BoxProps> & {
  draggableProps: Partial<DraggableProps>;
  containerSize: { height: number; width: number };
};

function DialogComponent(props: DialogComponentProps) {
  const { containerSize } = props;
  const resizeRef = useRef<HTMLDivElement>(null);
  const { height = 0, width = 0 } = useResizeObserver({ ref: resizeRef });

  const bounds = useMemo(
    () => ({ top: -containerSize.height + height / 2 }),
    [containerSize.height, height]
  );
  const { top } = resizeRef.current?.getBoundingClientRect() ?? { top: 0 };
  useEffect(() => {
    if (resizeRef.current) {
      const { top } = resizeRef.current.getBoundingClientRect();
      //   console.log(top, resizeRef.current.offsetTop);
      if (top < 0) {
        setPosition((last) => ({ x: last?.x!, y: last?.y! - top }));
      }
    }
  }, [resizeRef, height, containerSize.height, top]);

  const [position, setPosition] = useState<ControlPosition | undefined>(
    props.draggableProps.defaultPosition
  );

  // @ts-ignore
  return (
    <Draggable
      {...props.draggableProps}
      position={position}
      bounds={bounds}
      handle={".titleBar"}
      onDrag={(e, data) => setPosition({ x: data.x, y: data.y })}
    >
      <Box
        ref={resizeRef}
        className={styles.contentHolder}
        {...Object.fromEntries(
          Object.entries(props).filter(
            ([key]) => !["draggableProps", "containerSize"].includes(key)
          )
        )}
      />
    </Draggable>
  );
}

export function DraggableWindow(props: IDraggableWindowProps) {
  const { defaultPosition, open, title, offset, center } = props;

  const [isPopup, setIsPopup] = useState(false);

  const onClose = (event?: Object, reason?: string) => {
    if (reason === "backdropClick") {
      return;
    }
    props.onClose(event, reason);
  };

  const doClose = useCallback(() => {
    if (!isPopup) {
      onClose();
    } else {
      setIsPopup(false);
    }
  }, [isPopup]);

  const windowControls = (
    <Box className={styles.draggableWindow}>
      {!props.hidePopup && (
        <span
          className={styles.popModal}
          onClick={() => setIsPopup(true)}
          style={{ display: isPopup ? "none" : "inherit" }}
        >
          <KeyboardArrowUp sx={{ width: 15, height: 15 }} />
        </span>
      )}
      <span className={styles.closeModal} onClick={() => doClose()}>
        <CloseIcon sx={{ width: 15, height: 15 }} />
      </span>
      <Box className={styles.windowContent}>
        <Typography className={styles.title} fontWeight={"bold"}>
          {title}
        </Typography>
      </Box>
    </Box>
  );

  const [containerSize, setContainerSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useResizeObserver({
    ref: document.documentElement,
    onResize: (data) => {
      setContainerSize({ width: data.width ?? 0, height: data.height ?? 0 });
    },
  });

  const { ref: contentRef, width = 0, height = 0 } = useResizeObserver();

  const openPosition = useMemo(() => {
    return {
      x: width / 2 + (offset?.x ?? 0),
      y: -(height / 2 + (offset?.y ?? 0)),
    };
  }, [width, height, offset?.x, offset?.y]);

  const dPosition = useMemo(() => {
    let dPosition = openPosition;
    if (center) {
      dPosition = { x: containerSize.width / 2, y: -containerSize.height / 2 };
    }
    return dPosition;
  }, [defaultPosition?.x, defaultPosition?.y, center, openPosition]);

  const Drag = useMemo(
    () =>
      forwardRef<HTMLDivElement>(function Drag(itemProps, ref) {
        // const bounds = useMemo(() => ({top: (-containerSize.height) + (height/2)}), [containerSize.height, height])
        return (
          <DialogComponent
            containerSize={containerSize}
            draggableProps={{
              defaultPosition: dPosition,
              positionOffset: {
                x: -containerSize.width / 2,
                y: containerSize.height / 2,
              },
              // bounds: bounds ?? {top: 10, left: 0, right: window.innerWidth, bottom: window.innerHeight},
              offsetParent:
                document.querySelector("#__next")?.parentElement ??
                document.documentElement,
            }}
            {...itemProps}
          />
        );
      }),
    [containerSize.width, containerSize.height, dPosition.x, dPosition.y]
  );

  return (
    <Dialog
      classes={{ paper: styles.contentHolder }}
      open={open}
      onClose={onClose}
      PaperComponent={Drag}
      disableEnforceFocus
      disableAutoFocus
      hideBackdrop
      sx={{ pointerEvents: "none" }}
      PaperProps={{ style: { pointerEvents: isPopup ? "none" : "visible" } }}
    >
      <Box
        ref={contentRef}
        className={styles.contentHolder}
        style={{
          background: "#E6E6FA",
          position: "relative",
          border: "3px",
          visibility: isPopup ? "hidden" : "visible",
          padding: 4,
          maxHeight: props.height
            ? window.innerHeight
            : window.innerHeight * 0.8,
          overflowY: "clip",
        }}
      >
        <Box
          sx={{
            cursor: "move",
            visibility: !(isPopup && !props.popupToolbar)
              ? "visible"
              : "hidden",
          }}
          className="titleBar"
        >
          {windowControls}
        </Box>
        <WindowWrapper
          doClose={() => doClose()}
          isPopup={isPopup}
          setIsPopup={setIsPopup}
          width={width}
          height={height}
          {...props}
        >
          {props.children}
        </WindowWrapper>
      </Box>
    </Dialog>
  );
}

interface IWindowWrapperProps {
  title: string;
  doClose: Function;
  children: React.ReactNode | any;
  width: number;
  height: number;
  url?: string;
  popupToolbar?: boolean;
  hidePopup?: boolean;
  isPopup: boolean;
  setIsPopup: Dispatch<SetStateAction<boolean>>;
}

export function WindowWrapper(props: IWindowWrapperProps) {
  const { title, doClose, isPopup, setIsPopup, width, height } = props;

  const [stateChildren, setStateChildren] = useState(props.children);

  useMemo(() => {
    setStateChildren(props.children);
  }, [props.children]);

  const windowContent = (
    <div>
      <Box className="windowChildren">{stateChildren}</Box>
    </div>
  );

  return (
    <>
      {isPopup && (
        <MyWindowPortal>
          <NewWindow
            copyStyles={true}
            title={title}
            onUnload={() => doClose()}
            name={title}
            onOpen={(e) => console.log(e)}
            url={props.url}
            center={"screen"}
            onBlock={() => alert("Please enable popups")}
            features={{ height: height, width: width }}
          >
            {props.url ? null : windowContent}
          </NewWindow>
        </MyWindowPortal>
      )}

      {windowContent}
    </>
  );
}

export function MyWindowPortal(props: { children: React.ReactNode | any }) {
  const containerEl = useRef(document.createElement("div"));

  return createPortal(
    <CacheProvider value={clientSideEmotionCache}>
      {" "}
      {props.children}
    </CacheProvider>,
    containerEl.current
  );
}

export function DraggableIFrame(props: DraggableIFrameProps) {
  const { url, query, title, open, onClose, width } = props;

  const iframeRef = useRef(null);

  useEffect(() => {}, []);

  if (iframeRef.current) {
    // console.log(iframeRef.current);
    // @ts-ignore
    iframeRef.current?.resize();
  }

  return (
    <DraggableWindow
      defaultPosition={{ x: 100, y: 200 }}
      open={open}
      onClose={onClose}
      width={width}
      title={title}
      popupToolbar={false}
    >
      <IframeResizer
        forwardRef={iframeRef}
        heightCalculationMethod="taggedElement"
        inPageLinks
        log
        // onResized={({ iframe, height, width }) =>
        //   console.log(iframe, height, width)
        // }
        src={
          (url.includes("http") ? "" : window.location.href) +
          url +
          (query ?? "")
        }
        width={width}
        minHeight={500}
        // style={{ width: '1px', minWidth: '100%'}}
      />
    </DraggableWindow>
  );
}

interface DraggableIFrameProps {
  url: string;
  query?: string;
  title: string;
  height?: number;
  width?: number;
  open: boolean;
  onClose: () => void;
  autosize?: boolean;
}
