import {
  Box,
  Grid,
  IconButton,
  Paper,
  Slider,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { DraggableWindow } from "../DraggablePopup/DraggablePopup";
import {
  update,
  togglePlaying,
  calculateTimestamp,
  setTimestamp,
} from "../../redux/slices/replay";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime, Interval } from "luxon";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay30";
import ForwardIcon from "@mui/icons-material/Forward30";
import NoSsr from "@mui/material/NoSsr";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import Button from "@mui/material/Button";
import ReplayListWrapper, { FetchedPlayback } from "./ReplayList";
import { useInterval } from "../Header/useInterval";
import useSWR from "swr";
import { GridOff, GridOn, Stop } from "@mui/icons-material";
import axios from "axios";
import { CreateReplay } from "./CreateReplay";
import { clear, clearArray } from "../Map/Marker/trackerSlice";
import { clear as clearWindows } from "../../trackerWindowsSlice";
import Alarms = DBSchema.Alarms;

export default function ControlWindow(props: {
  open: boolean;
  defaultReplays: { replays: FetchedPlayback[] };
  onLoad?: () => void;
  onEnd?: () => void;
}) {
  const dispatch = useAppDispatch();
  const replaySettings = useAppSelector((state) => state.replay);
  const startDate = DateTime.fromMillis(replaySettings.startDate);
  const endDate = DateTime.fromMillis(replaySettings.endDate);
  // const timestamp = calculateTimestamp(replaySettings)
  const { onLoad, onEnd } = props;

  const [view, setView] = useState(
    replaySettings.isReplay ? "controls" : "list"
  );
  // const width = view === 'list' ? window.innerWidth / 4 : window.innerWidth / 3
  useEffect(() => {
    setView(replaySettings.isReplay ? "controls" : "list");
  }, [replaySettings]);

  const doExitReplay = (controlsOpen?: boolean) => {
    axios
      .post("/api/replay/sendCommand", {
        command: "stop",
      })
      .then((res) => {
        console.log(res);
        dispatch(
          update({
            running: false,
            isReplay: false,
            controlsOpen: controlsOpen ?? false,
          })
        );
        dispatch(clearWindows());
        dispatch(clear());
      })
      .catch(console.error);
    if (onEnd) {
      onEnd();
    }
  };

  const closeWindow = useCallback(() => {
    if (replaySettings.isReplay) {
      doExitReplay(true);
    } else {
      dispatch(
        update({ running: false, isReplay: false, controlsOpen: false })
      );
      dispatch(clearWindows());
      dispatch(clear());
    }
    if (onEnd) {
      onEnd();
    }
  }, [replaySettings]);

  const windowTitle = useMemo(() => {
    switch (view) {
      case "controls":
        return "Replay Controls";
      case "create":
        return "Create Replay";
      default:
        return "Replay Menu";
    }
  }, [view]);

  return (
    <DraggableWindow
      unsetBottom={true}
      center={true}
      open={props.open}
      onClose={() => closeWindow()}
      title={windowTitle}
      bounds={"parent"}
      hidePopup={view === "list" || !replaySettings.isReplay}
    >
      {/*<Grid container justifyContent={'space-between'} marginY={1}>*/}

      {/*<Button variant={'outlined'} size={'small'}*/}
      {/*        onClick={() => setView(old => old === 'list' ? 'controls' : 'list')}>{view === 'list' ? 'controls' : 'list'}</Button>*/}
      {/*<Button variant={'outlined'} size={'small'} onClick={() => dispatch(update({isReplay: false}))}>Disable Replay</Button>*/}
      {/*</Grid>*/}
      <Box>
        {view === "list" ? (
          <ReplayListWrapper
            defaultReplays={props.defaultReplays}
            openControls={() => setView("controls")}
          />
        ) : view === "create" ? (
          <CreateReplay doClose={() => setView("list")} />
        ) : (
          <ReplayContainer
            onLoad={onLoad}
            replayName={replaySettings.name}
            startDate={startDate}
            endDate={endDate}
            doExitReplay={doExitReplay}
          />
        )}
        {view === "list" && (
          <Button
            sx={{ marginTop: 0.5 }}
            variant={"contained"}
            onClick={() => setView("create")}
          >
            Create
          </Button>
        )}
      </Box>
    </DraggableWindow>
  );
}

interface ReplayContainerProps {
  startDate: DateTime;
  endDate: DateTime;
  replayName: string;
  doExitReplay: (boolean) => void;
  onLoad?: () => void;
}

export function ReplayContainer(props: ReplayContainerProps) {
  const { startDate, endDate, replayName, doExitReplay } = props;
  const replaySettings = useAppSelector((state) => state.replay);
  const interval = Interval.fromDateTimes(startDate, endDate);

  const [timestamp, setLocalTimestamp] = useState(
    calculateTimestamp(replaySettings)
  );
  const [showDetail, setShowDetail] = useState(false);

  const getTimestamp = useCallback(
    () => calculateTimestamp(replaySettings),
    [replaySettings]
  );

  const running = replaySettings.running;
  const firstLoad = replaySettings.firstLoad;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!props.onLoad && firstLoad) {
      props.onLoad();
    }
  }, [firstLoad]);
  // const updateLocalTimestamp = useCallback(() => {
  // if(running) {
  //     setLocalTimestamp(getTimestamp())
  // }
  // },[running])
  useInterval(() => setLocalTimestamp(getTimestamp()), 10);

  useEffect(() => {
    // console.log(timestamp.toMillis(), getTimestamp().toMillis())
    // if(timestamp.toMillis() !== getTimestamp().toMillis()) {
    //     console.log("updating time")
    //     dispatch(update({timestamp: timestamp.toISO({includeOffset: false})}))
    // }
  }, [timestamp]);

  const doSetTimestamp = useCallback(
    (newTimestamp: number) => {
      dispatch(setTimestamp(newTimestamp));
      // if(running) {
      sendTimestamp(DateTime.fromMillis(newTimestamp), true).catch(
        console.error
      );
      // }
    },
    [running]
  );

  const doToggleRunning = useCallback(() => {
    dispatch(togglePlaying());
  }, [running, timestamp]);

  // useEffect(() => {
  //     sendRunning(replaySettings.running).then(res => {
  //         if(res?.running != running) {
  //             dispatch(update({running: res.running ?? running}))
  //         }
  //     }).catch(console.error)
  //     sendTimestamp(getTimestamp()).catch(console.error)
  // },[running])

  const doSendRunning = useCallback(
    (newState: boolean) => {
      dispatch(update({ firstLoad: false }));
      sendRunning(newState, firstLoad, timestamp).then((res) => {
        if (typeof res.running != "boolean") {
          return;
        }
        dispatch(togglePlaying(res?.running));
      });
    },
    [running, timestamp, firstLoad]
  );

  useEffect(() => {
    console.log(replaySettings.currentIMEIs);
    dispatch(clearArray(replaySettings.currentIMEIs));
  }, [replaySettings.currentIMEIs]);

  // useEffect(() => {
  //     const send = DateTime.fromMillis(replaySettings.lastTimestamp)
  //     sendTimestamp(send).catch(console.error)
  // },[replaySettings.lastTimestamp])

  // console.log(timestamp, interval.start)
  const { progress } = calculateProgress(
    interval,
    timestamp.toMillis() - interval.start.toMillis()
  );

  //@ts-ignore
  const fetcher = (...args) => fetch(...args).then((res) => res.json());

  const { data: replayInfo } = useSWR<ReplayInfo>(
    `/api/replay/info?start=${startDate.toISO()}&end=${endDate.toISO()}`,
    fetcher
  );

  return (
    <NoSsr>
      <Grid container justifyContent={"center"}>
        <Paper sx={{ padding: 1, minWidth: "99%", margin: 1 }}>
          <Table size={"small"}>
            {!showDetail && (
              <TableRow onClick={() => setShowDetail(true)}>
                <TableCell>
                  <Typography fontWeight={"bold"}>{replayName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {getFormattedDateTime(interval.start)} to{" "}
                    {getFormattedDateTime(interval.end)}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {showDetail && (
              <>
                <TableRow onClick={() => setShowDetail(false)}>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{replayName}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>Start Time</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {interval.start.toISO({ includeOffset: false })}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>End Time</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {interval.end.toISO({ includeOffset: false })}
                    </Typography>
                  </TableCell>
                </TableRow>
                {Object.entries(replayInfo?.timestamps ?? {}).map((entry) => (
                  <ReplayInfoRow key={entry[0] + "-info-row"} entry={entry} />
                ))}
              </>
            )}
            <TableRow>
              <TableCell>
                <Typography>Timestamp</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {timestamp.toISO({ includeOffset: false })} (
                  {progress.toFixed(2)}%)
                </Typography>
              </TableCell>
            </TableRow>
          </Table>

          <ControlsContainer
            replayInfo={replayInfo}
            interval={interval}
            timestamp={timestamp}
            setTimestamp={doSetTimestamp}
            running={running}
            toggleRunning={doSendRunning}
            doExitReplay={doExitReplay}
          />
        </Paper>
      </Grid>
    </NoSsr>
  );
}

export function ReplayInfoRow(props: { entry: any }) {
  const { entry } = props;
  const [showTimestamps, setShowTimestamps] = useState(false);
  return (
    <>
      <TableRow
        onClick={() => setShowTimestamps((old) => !old)}
        key={entry[0] + "-count"}
      >
        <TableCell>{`${entry[0]}`}</TableCell>
        <TableCell>{`${entry[1].length}`}</TableCell>
      </TableRow>
      {showTimestamps &&
        entry[1].map((alarm, index) => (
          <TableRow key={entry[0] + "-item-" + index}>
            <TableCell>{alarm?.vtuid ?? alarm?.imei}</TableCell>
            <TableCell>{alarm?.time!}</TableCell>
            <TableCell>{alarm?.alert!}</TableCell>
          </TableRow>
        ))}
    </>
  );
}

function getFormattedDateTime(time: DateTime) {
  return (
    time.toLocaleString({ dateStyle: "short" }) +
    ", " +
    time.toLocaleString({ timeStyle: "short", hourCycle: "h23" })
  );
}

interface ControlsContainerProps extends TimeProps {
  doExitReplay: (boolean) => void;
  replayInfo?: ReplayInfo;
}
function ControlsContainer(props: ControlsContainerProps) {
  // const [success, setSuccess] = useState(false)

  // useEffect(() => {
  //     const period = 100
  //     // sendRunning(running, )
  //     if(running) {
  //         // sendTimestamp(timestamp).then(console.log).catch(console.error)
  //         const timer = setInterval(() => {
  //             setTimestamp((prevTimestamp) => {
  //                 const newTimestamp = prevTimestamp.plus({millisecond: period})
  //                 if(!interval.contains(newTimestamp)) {
  //                     toggleRunning(false)
  //                     return interval.end
  //                 }
  //                 return newTimestamp
  //             })
  //         }, period)
  //         return () => clearInterval(timer)
  //     }
  // },[running])

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Grid item>
        <ReplayTimeline {...props} />
      </Grid>
      <Grid item>
        <ReplayControls {...props} />
      </Grid>
    </Grid>
  );
}

interface ReplayControlsProps extends TimeProps {
  doExitReplay: (boolean) => void;
}

export function ReplayControls(props: ReplayControlsProps) {
  const {
    running,
    toggleRunning,
    timestamp,
    setTimestamp,
    interval,
    doExitReplay,
  } = props;

  const addToTimestamp = useCallback(
    (millis: number) => {
      console.log("adding " + millis);
      let newTimestamp = timestamp.plus({ millisecond: millis });
      if (interval.start > newTimestamp) {
        newTimestamp = interval.start;
      }
      if (interval.end < newTimestamp) {
        newTimestamp = interval.end;
      }
      sendJump(millis).catch(console.error);
      // setTimestamp(newTimestamp.toMillis())
      // sendTimestamp(newTimestamp).catch(console.error)
      // if(running) {
      //     sendTimestamp(newTimestamp).catch(console.error)
      // }
    },
    [timestamp, interval]
  );

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <IconButton onClick={() => setTimestamp(interval.start.toMillis())}>
        <FirstPageIcon fontSize={"large"} color={"primary"} />
      </IconButton>
      <IconButton onClick={() => addToTimestamp(-30000)}>
        <ReplayIcon fontSize={"large"} color={"primary"} />
      </IconButton>
      <IconButton
        color={running ? "error" : "primary"}
        onClick={() => toggleRunning(!running)}
      >
        {running ? (
          <PauseIcon fontSize={"large"} />
        ) : (
          <PlayArrowIcon fontSize={"large"} />
        )}
      </IconButton>
      <IconButton onClick={() => addToTimestamp(30000)}>
        <ForwardIcon fontSize={"large"} color={"primary"} />
      </IconButton>
      <IconButton color={"error"} onClick={() => doExitReplay(true)}>
        <Stop fontSize={"large"} />
      </IconButton>
    </Grid>
  );
}

interface ReplayTimelineProps extends TimeProps {
  replayInfo?: ReplayInfo;
}

function calculateProgress(interval: Interval, value: number) {
  const date = interval.start
    .plus({ millisecond: value })
    .set({ millisecond: 0 })
    .toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    });
  const duration = interval.toDuration().toMillis();
  const progress = (value / duration) * 100;
  return { date, progress };
}

function ReplayTimeline(props: ReplayTimelineProps) {
  const { timestamp, setTimestamp, interval, running } = props;
  const diff = timestamp.diff(interval.start);
  const changeTimestamp = (value, commit) => {
    if (running) {
      console.log("replay is running, refusing to change timestamp");
      return;
    }
    if (typeof value !== "number") {
      console.error(
        "got unexpected type for timestamp value",
        value,
        typeof value
      );
      return;
    }
    const newTimestamp = interval.start.plus({ millisecond: value });
    const newValue = newTimestamp.toMillis();
    if (commit) {
      console.log("change committed");
      setTimestamp(newValue);
      // console.log(newTimestamp)
      // sendTimestamp(newTimestamp).then(console.log).catch(console.error)
    }
    // console.log("changing local timestamp")
    setSliderValue(value);
  };

  const showDateInMarks = interval.start.day !== interval.end.day;
  const getMarkLabel = (time: DateTime, showDate: boolean) =>
    showDate
      ? time.toISO({ includeOffset: false, suppressMilliseconds: true })
      : time.toISOTime({ includeOffset: false, suppressMilliseconds: true });

  const duration = interval.toDuration().toMillis();

  const eventMarks: Array<{ value: number; label: string; name?: string }> =
    useMemo(() => {
      return (
        props.replayInfo?.timestamps?.alarms.map((alarm) => {
          //@ts-ignore
          const time = DateTime.fromISO(alarm.time);
          const diff = time.diff(interval.start).toMillis();
          return {
            value: diff,
            label: "",
            name: `${alarm?.vtuid ?? alarm?.imei} - ${alarm?.alert}`,
          };
        }) ?? []
      );
    }, [
      interval.start,
      interval.end,
      props.replayInfo?.timestamps?.alarms.length,
    ]);

  const marks = [
    {
      value: 0,
      label: getMarkLabel(interval.start, showDateInMarks),
    },
    ...eventMarks,
    {
      value: duration,
      label: getMarkLabel(interval.end, showDateInMarks),
    },
  ];

  const [sliderValue, setSliderValue] = useState(diff.milliseconds);
  useEffect(() => {
    setSliderValue(diff.milliseconds);
  }, [diff.milliseconds]);

  // by having the color in state, we load with 'error' and replace it with 'primary' after the first render
  // this lets us load the 'error' style before the user opens the popup. if the popup opens before the 'error'
  // style has been loaded, it causes issues
  const [color, setColor] = useState("error");
  useEffect(() => {
    setColor(running ? "error" : "primary");
  }, [running]);

  const [step, setStep] = useState<number | null>(1);

  return (
    <Grid container spacing={3} justifyItems={"center"} sx={{ width: 300 }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Slider
          value={sliderValue}
          min={0}
          max={interval.toDuration().toMillis()}
          onChange={(event, value) => changeTimestamp(value, false)}
          onChangeCommitted={(event, value) => changeTimestamp(value, true)}
          {
            //@ts-ignore
            ...{}
          }
          //@ts-ignore
          color={color}
          step={step}
          getAriaValueText={(value) =>
            DateTime.fromMillis(value).toISO({ includeOffset: false })
          }
          valueLabelFormat={(value) => {
            let markItem = <></>;
            const markIndex = eventMarks.findIndex(
              (mark) => mark.value === value
            );
            if (markIndex >= 0) {
              const name = eventMarks[markIndex].name;
              if (name) {
                markItem = <Typography variant={"body2"}>{name}</Typography>;
              }
            }
            const { date, progress } = calculateProgress(interval, value);
            return (
              <>
                {markItem}
                <Typography
                  variant={"body2"}
                >{`${date} (${progress.toFixed(2)}%)`}</Typography>
              </>
            );
          }}
          valueLabelDisplay={"auto"}
          marks={marks}
        />
      </Grid>
      <Grid item xs={1}>
        <Tooltip
          title={step ? "Enable event snapping" : "Disable event snapping"}
        >
          <IconButton
            onClick={() => setStep((oldStep) => (oldStep === null ? 1 : null))}
          >
            {step ? <GridOn /> : <GridOff />}
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

async function sendTimestamp(timestamp: DateTime, includeOffset = false) {
  // const res = await fetch("/api/replay/trackerSender/setTimestamp?timestamp="+timestamp.toISO({includeOffset: false}))
  const res = await axios.post("/api/replay/sendCommand", {
    command: "setTimestamp",
    timestamp: timestamp.toUTC().toMillis(),
  });
  return res.data;
  // return console.log('sending timestamp = ' + timestamp)
}

async function sendJump(diffms: number) {
  const res = await axios.post("/api/replay/sendCommand", {
    command: "jump",
    diff: `${diffms}ms`,
  });
  return res.data;
}

async function sendRunning(
  running: boolean,
  firstLoad?: boolean,
  timestamp?: DateTime
) {
  // const tsString = timestamp ? `?timestamp=${timestamp.toISO({includeOffset: false})}` : ""
  // const url = running ? "/api/replay/trackerSender/resume" : "/api/replay/trackerSender/pause"
  let timestampObject = timestamp ? { timestamp: timestamp.toMillis() } : {};
  console.log(running, firstLoad, timestamp);
  const res = await axios.post(
    "/api/replay/sendCommand",
    {
      ...timestampObject,
      command: firstLoad ? "start" : running ? "resume" : "pause",
    },
    { params: { timestamp: timestamp?.toMillis() } }
  );

  return res.data;
}

interface TimeProps {
  interval: Interval;
  timestamp: DateTime;
  setTimestamp: (timestamp: number) => any;
  running: boolean;
  toggleRunning: Function;
}

export interface ReplayInfo {
  start: Date;
  end: Date;
  counts: Counts;
  timestamps: { alarms: Partial<Alarms[]> };
}

export interface Counts {
  Alarms: string;
  "Booking Changes": string;
  "Barrier Requests": string;
  "LTE Log": string;
  "Track Log": string;
}
